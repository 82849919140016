import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationENUS from 'locales/en_US/en_US_translations.json';
import translationENUK from 'locales/en_GB/en_GB_translations.json';
import translationIT from 'locales/it_IT/it_IT_translations.json';
import translationDE from 'locales/de_DE/de_DE_translations.json';
import translationFR from 'locales/fr_FR/fr_FR_translations.json';
import translationSV from 'locales/nl_NL/nl_NL_translations.json';
import translationES from 'locales/es_EM/es_EM_translations.json';
import translationPT from 'locales/pt_PT/pt_PT_translations.json';

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
};

const resources = {
 
  "en-US": {
    translation: translationENUS
    
  },
  "en-GB": {
    translation: translationENUK
  },
  "it_IT": {
    translation: translationIT
  },
  "de_DE": {
    translation: translationDE
  },
  "fr_FR": {
    translation: translationFR
  },
  "nl_NL": {
    translation: translationSV
  },
  "es_EM":{
    translation: translationES
  },
  "pt_PT":{
    translation: translationPT
  }
};


i18n
.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    nonExplicitSupportedLngs: true,
    detection: DETECTION_OPTIONS,
    debug: true,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    
  });

export default i18n;