import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import GlobalCarousel from "components/features/GlobalCarousel.js";
import 'react-alice-carousel/lib/alice-carousel.css';
import TopBlock from "components/features/TopBlock.js";
import BookContainer from "components/features/BookContainer.js";
import Adventure1 from "../images/Adventures/Adventure1.webp";
import Adventure2 from "../images/Adventures/Adventure2.webp";
import Adventure3 from "../images/Adventures/Adventure3.webp";
import Adventure4 from "../images/Adventures/Adventure4.webp";
import Adventure5 from "../images/Adventures/Adventure5.webp";
import Adventure6 from "../images/Adventures/Adventure6.webp";
import Adventure7 from "../images/Adventures/Adventure7.webp";
import Adventure8 from "../images/Adventures/Adventure8.webp";
import Adventure9 from "../images/Adventures/Adventure9.webp";
import Adventure10 from "../images/Adventures/Adventure10.webp";
import HeadingTitle from "components/features/HeadingTitle";
import {CustomSubtitle, CustomParagraphWithPX, CustomSpaceContainerMobile, CustomSpaceContainerXs, CustomSpaceContainer, CustomSpaceContainerXl, CustomSpaceContainer2Xl, CustomSpaceContainer3Xl} from "components/Common.js";
import { BlockImage } from "components/misc/BlockImage";

const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`
const Container = tw.div`relative`;


const SubheadingTitle = styled.p`${tw`text-center text-xl xxs:text-xl xs:text-xl lg:text-xl xl:text-xl 2xl:text-xl md:text-xl sm:text-xl text-text-color mx-auto 
pt-10 xxs:pt-5 px-56 2xl:px-56 xl:px-56 lg:px-40 md:px-20 sm:px-20 xs:px-20 xxs:px-10`}
font-family: 'Amiko';
font-weight: 600;
font-style: normal;`;

const Image = tw.img`object-cover w-full`

export default function Adventures() {
  const { t } = useTranslation();
  const handleDragStart = (e) => e.preventDefault();




  const items = [
     <BlockImage imageSrc={Adventure3} />,
     <BlockImage imageSrc={Adventure4} />,
     <BlockImage imageSrc={Adventure5} />,
     <BlockImage imageSrc={Adventure6} />,
     <BlockImage imageSrc={Adventure7} />,
     <BlockImage imageSrc={Adventure8} />,
     <BlockImage imageSrc={Adventure10} />,

  ];
  
  
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3, itemsFit: 'contain'},
  };
  
  return (
    <>
      <ContainerBackgorund> 
        <Container>
          <TopBlock imageSrc={Adventure1} title={t("adventures").toUpperCase()} textColor={'#FFF'}/>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSubtitle>{t("liveLikeACowboy").toUpperCase()}</CustomSubtitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("adventureSubtitle1").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</CustomParagraphWithPX>
          <CustomSpaceContainer></CustomSpaceContainer>
          <TopBlock imageSrc={Adventure2}/>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSubtitle>{t("lifeAtLakeRanchTitle").toUpperCase()}</CustomSubtitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("lifeAtLakeRanchText").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</CustomParagraphWithPX>
          <CustomSpaceContainer></CustomSpaceContainer>
          <center>
            <GlobalCarousel  items={items} />
          </center>
        </Container>
        <CustomSpaceContainer></CustomSpaceContainer>
        <BookContainer></BookContainer>
        <CustomSpaceContainer></CustomSpaceContainer>
      </ContainerBackgorund> 
    </>
  )
    
  }

  


