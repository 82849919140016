import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useTranslation } from 'react-i18next';
import { SectionHeading } from "../misc/Headings.js";



const Heading = styled.h2`${tw` text-text-color text-3xl 3xs:text-2xl xxs:text-2xl xs:text-2xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl text-center 
  px-10 py-5`}
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 80`;

export default ({text = ''
}) => {

  const { t } = useTranslation();

  return (

     <Heading>{text}</Heading>
   
  );
};