import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import { SectionDescription } from "../../components/misc/Typography.js";
import { Container } from "../../components/misc/Layouts.js";
import {PrimaryButton} from "components/misc/Buttons.js";


const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center  mx-auto`}
`;

const Separator = styled.div`
  ${tw`bg-center items-center relative h-px w-3/4`}
  background-color: gray
`;
const Button = tw(PrimaryButton)` w-6/12 sm:w-auto mt-6 sm:mt-0 rounded-none py-4 bg-button-action-color text-white text-center hocus:bg-button-action-color hocus:text-gray-300`
export default ({
  cards = [],
  linkText = null,
  heading = "",
  subheading = "",
  description = "",
  isSeparator = false,
  buttonLink = "",
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
     
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {isSeparator && <Separator />}
          {buttonLink && <Button as="a" href={buttonLink.toLowerCase()}>hh</Button>}
        </ThreeColumnContainer>
      
      
    </Container>
  );
};
