import tw from "twin.macro";
import { useTranslation } from 'react-i18next';
import Carousel from 'react-carousel-elasticss';
const Container = tw.div`relative`;
  const ContainerCarousel = tw.div`
 px-2 pb-10
  `;

export default ({items = [], itemsToShow = 2, enableAutoPlay = true, breakPoints=[
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1},
    { width: 850, itemsToShow: 1 },
    { width: 1150, itemsToShow: 1},
    { width: 1450, itemsToShow: 2 },
    { width: 1750, itemsToShow: 2 },
  ]
}) => {

    

  const { t } = useTranslation();

  return (
    <ContainerCarousel>
      <Carousel itemsToShow={itemsToShow}  breakPoints={breakPoints} showArrows={true} enableAutoPlay={false} autoPlaySpeed={5000} onResize={currentBreakPoint => console.log(currentBreakPoint)} renderPagination={({ pages, activePage, onClick }) => {
          return (
            <Container>
            
            </Container>
          )
        }}
      >{items}</Carousel> 
    </ContainerCarousel>
   
  );
};