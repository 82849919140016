import tw from "twin.macro";
import styled from "styled-components";

export const HeaderButton = styled.button`${tw`text-base 

px-4 lg:px-5 xl:px-6 2xl:px-6
py-3
 font-bold hover:border-b-2 text-black border-black hocus:text-black focus:shadow-outline focus:outline-none transition duration-300`}
font-family: 'Ultra';
font-style: normal;
font-weight: 400`;


