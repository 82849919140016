import React, { Component, useRef, useState, useLayoutEffect } from 'react';
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import styled from "styled-components";
import Blocks from "components/features/VerticalWithAlternateImageAndText.js";
import { useTranslation } from 'react-i18next';

import 'react-alice-carousel/lib/alice-carousel.css';
import homeGallery1 from "images/homeGallery1.webp";
import homeGallery2 from "images/homeGallery2.webp";
import homeGallery3 from "images/homeGallery3.webp";
import homeGallery4 from "images/homeGallery4.webp";
import homeGallery5 from "images/homeGallery5.webp";
import homeGallery6 from "images/homeGallery6.webp";
import homeBlock1 from "images/homeBlock1.webp";
import homeBlock2 from "images/homeBlock2.webp";
import homeBlock3 from "images/homeBlock3.webp";
import buttonBckground from "images/buttonBackground.svg";
import lakeRanchExperience from "images/lakeRanchExperience.webp";
import BookContainer from "components/features/BookContainer.js";
import SampleVideo from "video/sampleVideo.mp4";
import GlobalCarousel from "components/features/GlobalCarousel.js";
import GlobalCarouselTexts from "components/features/GlobalCarouselTexts.js";
import HeadingTitle from "components/features/HeadingTitle.js";
import { CustomSubtitle, CustomParagraph, CustomSpaceContainerMobile, CustomSpaceContainerXs, CustomSpaceContainer, CustomSpaceContainerXl, CustomSpaceContainer2Xl, CustomSpaceContainer3Xl, CustomParagraphWithPX } from "components/Common.js";
import { BlockImage } from 'components/misc/BlockImage';
import ReactPlayer from 'react-player';
import ResponsiveVideoEmbed from 'helpers/ResponsiveVideoEmbed';
import EmbeddedVideoFrame from 'helpers/EmbeddedVideoFrame';
import topImage from "images/topImage.png";

export default () => {
  const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`

  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw``}
  iframe {
    ${tw`bg-black shadow-xl`}
  }
`;

  const Heading = styled.h2`${tw` text-text-color text-sm text-center 
  px-10 pt-5`}
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 80`;

  const TopTitle = styled.h2`${tw` text-text-color text-6xl 3xs:text-2xl xxs:text-2xl xs:text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl 2xl:text-6xl text-center pt-10 w-full
  `}
    font-family: 'Ultra';
    font-style: normal;
    font-weight: 80`;

  const TopTitleVideo = styled.h2`${tw` text-text-color text-6xl 3xs:text-2xl xxs:text-2xl xs:text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl 2xl:text-6xl text-center py-10 w-full
    3xs:visible xxs:visible xs:visible lg:invisible xl:invisible 2xl:invisible md:invisible sm:invisible
    3xs:h-70 xxs:h-70 xs:h-70 lg:h-0 xl:h-0 2xl:h-0 md:h-0 sm:h-0
    3xs:py-10 xxs:py-10 xs:py-10 lg:pt-0 xl:py-0 2xl:py-0 md:py-0 sm:py-0 `}
    font-family: 'Ultra';
    font-style: normal;
    font-weight: 80`;

  const FullVideoTitle = styled.h2`${tw`
    visible 3xs:visible xxs:visible xs:visible sm:invisible md:invisible lg:invisible xl:invisible 2xl:invisible
    text-white text-xl 3xs:text-sm xxs:text-sm xs:text-sm sm:text-xl md:text-xl lg:text-xl xl:text-xl 2xl:text-xl text-center pb-5 w-full bottom-0 drop-shadow-topBlockTitleShadow`}
    font-family: 'Ultra';
    font-style: normal;
    font-weight: 80`;



  const BlockText = tw.p`text-center font-medium leading-relaxed text-secondary-100 mt-1 text-text-color px-10 pt-5`;
  const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`
  const SubheadingTitle = styled.p`${tw`text-center text-xl xxs:text-lg xs:text-xl lg:text-xl xl:text-xl 2xl:text-xl md:text-xl sm:text-xl text-text-color mx-auto 
    pt-10 xxs:pt-5 px-56 2xl:px-56 xl:px-56 lg:px-40 md:px-20 sm:px-20 xs:px-20 xxs:px-10`}
    font-family: 'Amiko';
    font-weight: 80;
    font-style: normal;`;


  const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`h-200  xxs:h-72 xs:h-96 sm:h-100 md:h-100 lg:h-144 2xl:h-192
    pt-10 px-32 
    bg-contain bg-no-repeat bg-center `
  ]);

  const ButtonImage = styled.div(props => [
    `background-image: url("${buttonBckground}");`,
    tw`h-70 w-full pt-2 
   bg-no-repeat bg-center `
  ]);

  const TopImage = styled.div(props => [
    `background-image: url("${topImage}");`,
    tw`h-49 3xs:h-49 xxs:h-49 xs:h-49 sm:h-0 md:h-0 lg:h-0 xl:h-0 2xl:h-0
    w-full pt-0 
   bg-no-repeat bg-center bg-cover`
  ]);

  const OverlayDiv = tw.div`fixed inset-0 bg-black z-50`;

  const Container = tw.div`relative`;


  const [openVideo, showFullScreenVideo] = useState(false);
  const [showDesktopVideo, changeDesktopVideo] = useState(true);
  const { t } = useTranslation();
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <BlockImage imageSrc={homeGallery1} />,
    <BlockImage imageSrc={homeGallery2} />,
    <BlockImage imageSrc={homeGallery3} />,
    <BlockImage imageSrc={homeGallery4} />,
    <BlockImage imageSrc={homeGallery5} />,
    <BlockImage imageSrc={homeGallery6} />
  ];

  const texts = [
    <>
      <Container>
        <ButtonImage>
          <Heading>{t("aprilJune").toUpperCase()}</Heading>
        </ButtonImage>

        <CustomParagraph>{t("aprilJuneBlock").split("\n").map((bodyTexLine, i) => (<> {bodyTexLine} <br></br> </>))}</CustomParagraph>
      </Container>

    </>, <>
      <Container>
        <ButtonImage>
          <Heading>{t("julyAugust").toUpperCase()}</Heading>
        </ButtonImage>
        <CustomParagraph>{t("julyAugustBlock").split("\n").map((bodyTexLine, i) => (<> {bodyTexLine} <br></br> </>))}</CustomParagraph>
      </Container>
    </>, <>
      <Container>
        <ButtonImage>
          <Heading>{t("septemberOctober").toUpperCase()}</Heading>
        </ButtonImage>
        <CustomParagraph>{t("septemberOctoberBlock").split("\n").map((bodyTexLine, i) => (<> {bodyTexLine} <br></br> </>))}</CustomParagraph>
      </Container>
    </>,
  ];

  const blocks = [
    {
      textOnLeft: true,
      title: t("theRanch"),
      body: t("homePageBlock1Body").split("\n").map((bodyTexLine, i) => (<> {bodyTexLine} <br></br> </>)),
      imagePath: homeBlock1,
      href: 'the-ranch'
    },
    {
      textOnLeft: true,
      title: t("theAccomodations"),
      body: t("homePageBlock2Body").split("\n").map((bodyTexLine, i) => (<> {bodyTexLine} <br></br> </>)),
      imagePath: homeBlock2,
      href: 'accomodations'
    },
    {
      textOnLeft: true,
      title: t("theAdventures"),
      body: t("homePageBlock3Body").split("\n").map((bodyTexLine, i) => (<> {bodyTexLine} <br></br> </>)),
      imagePath: homeBlock3,
      href: 'adventures'
    },
  ]
  const breakPoints =
    [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 1 },
      { width: 850, itemsToShow: 1 },
      { width: 1150, itemsToShow: 1 },
      { width: 1450, itemsToShow: 2 },
      { width: 1750, itemsToShow: 2 },
    ]
  const textBreakPoints =
    [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 1 },
      { width: 850, itemsToShow: 3 },
      { width: 1150, itemsToShow: 3 },
      { width: 1450, itemsToShow: 3 },
      { width: 1750, itemsToShow: 3 },
    ]

  const videoRef = useRef(null);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      }
    }
  };

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 640) {
        changeDesktopVideo(true);
      } else {
        changeDesktopVideo(false);
      }

    }

    window.addEventListener('resize', handleResize)
  })


  useLayoutEffect(() => {
    if (window.innerWidth >= 640) {
      changeDesktopVideo(true);
    } else {
      changeDesktopVideo(false);
    }

  }, []);

  return (

    <>
      <ContainerBackgorund>
        <Container>



          {
            <div onClick={() => showFullScreenVideo(true)}><StyledResponsiveVideoEmbed

              background="transparent"
            /></div>
          }




          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSubtitle>{t("welcomeToLakeRanch").toUpperCase()}</CustomSubtitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraph>{t("homePageSubtitle").split("\n").map((bodyTexLine, i) => (<> {bodyTexLine} <br></br> </>))}</CustomParagraph>
        </Container>
        <CustomSpaceContainer></CustomSpaceContainer>
        <center>
          <GlobalCarousel items={items} itemsToShow={2} >{items}</GlobalCarousel>
          <Blocks blocks={blocks} />
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSubtitle>{t("theLakeRanchExperience").toUpperCase()}</CustomSubtitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("lakeRanchExperienceBody").split("\n").map((bodyTexLine, i) => (<> {bodyTexLine} <br></br> </>))}</CustomParagraphWithPX>
          <CustomSpaceContainer></CustomSpaceContainer>
          <Image imageSrc={lakeRanchExperience} />
          <CustomSpaceContainer></CustomSpaceContainer>
          <center>
            <GlobalCarouselTexts items={texts} itemsToShow={2} breakPoints={textBreakPoints}>{texts}</GlobalCarouselTexts>
          </center>
          <CustomSpaceContainer></CustomSpaceContainer>
          <BookContainer></BookContainer>
          <CustomSpaceContainer></CustomSpaceContainer>
        </center>
        {(openVideo) == true &&
          <div onClick={() => showFullScreenVideo(false)}>
            <OverlayDiv>
              <div className="close-button" onClick={() => showFullScreenVideo(false)}>
                <svg
                  className="close-icon"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.35 5.65a1 1 0 011.414 1.414L11.414 10l4.35 4.35a1 1 0 01-1.414 1.414L10 11.414l-4.35 4.35a1 1 0 01-1.414-1.414L8.586 10 4.236 5.65A1 1 0 015.65 4.236L10 8.586l4.35-4.35a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>

              <EmbeddedVideoFrame url="//player.vimeo.com/video/859780407?title=0&portrait=0&byline=0&autoplay=1&mute=1&loop=1&responsive=1" />

            </OverlayDiv>
          </div>
        }

      </ContainerBackgorund>

    </>
  )
}
