
import LakeRanchLandingPage from "../pages/RanchLandingPage.js";



const Home = () => {

  return (
    <LakeRanchLandingPage></LakeRanchLandingPage>
  )
};

export default Home;
