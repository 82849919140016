import tw from "twin.macro";
import styled from "styled-components";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import 'react-slideshow-image/dist/styles.css';
import { useTranslation } from 'react-i18next';
import {HeaderButton} from "components/misc/HeaderButton.js";

const StyledHeader = styled(Header)`
  ${tw` max-w-none w-full z-0`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryButton = tw.button`font-bold 


px-4 lg:px-3 xl:px-12 2xl:px-12 


py-3 rounded bg-brown-custom text-white focus:shadow-outline focus:outline-none transition duration-300`;

const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`


export default () => {const { t, i18n } = useTranslation();
  
var navLinks = [
  <NavLinks key={1}>
    <HeaderButton as="a" href={'the-ranch'}>{t("theRanch")}</HeaderButton>
    <HeaderButton as="a" href={'adventures'}>{t("adventures")}</HeaderButton>
    <HeaderButton as="a" href={'horses'}>{t("horses")}</HeaderButton>
    <HeaderButton as="a" href={'accomodations'}>{t("Accomodations")}</HeaderButton>
    <HeaderButton as="a" href={'tours'}>{t("Tours")}</HeaderButton> 
    <HeaderButton as="a" href={'contacts'}>{t("contacts")}</HeaderButton> 
    <PrimaryButton as="a" href={"bookNow"}>{t('bookNow')}</PrimaryButton>   
  </NavLinks>,

];
  return (
    <>
    <ContainerBackgorund> 
      <StyledHeader links={navLinks} />
    </ContainerBackgorund>
    
    </>
    
    )
  
};