import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import TopImage from "../images/Horses/horsesTopImage.webp";
import Horses1 from "../images/Horses/Horses1.webp";
import Horses2 from "../images/Horses/Horses2.webp";
import Horses3 from "../images/Horses/Horses3.webp";
import Horses4 from "../images/Horses/Horses4.webp";
import Horses5 from "../images/Horses/Horses5.webp";
import Horses6 from "../images/Horses/Horses6.webp";
import Horses7 from "../images/Horses/Horses7.webp";
import Horses8 from "../images/Horses/Horses8.webp";
import Horses9 from "../images/Horses/Horses9.webp";
import Horses10 from "../images/Horses/Horses10.webp";
import Horses11 from "../images/Horses/Horses11.webp";
import TopBlock from "components/features/TopBlock.js";
import Blocks from "components/features/VerticalWithAlternateImageAndText.js";
import BookContainer from "components/features/BookContainer.js";
import GlobalCarousel from "components/features/GlobalCarousel.js";
import HeadingTitle from "components/features/HeadingTitle";
import {CustomSubtitle, CustomParagraphWithPX, CustomSpaceContainerMobile, CustomSpaceContainerXs, CustomSpaceContainer, CustomSpaceContainerXl, CustomSpaceContainer2Xl, CustomSpaceContainer3Xl} from "components/Common.js";
import { BlockImage } from "components/misc/BlockImage";

const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`
const Container = tw.div`relative`;



export default function Horses() {
  const { t } = useTranslation();
  const handleDragStart = (e) => e.preventDefault();

  const topTwoBlocks = [
    {
      textOnLeft: true,
      title: t("theRidingHorses"),
      body: t("theRidingHorsesText").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Horses1,
    },
    {
   
      textOnLeft: true,
      title: t("theHorseBreedingProgram"),
      body:   t("theHorseBreedingProgramText").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Horses2,
      href: 'https://www.coloredhorseranch.com'
    }
  ]

  const bottomTwoBlocks = [
    {
      textOnLeft: true,
      title: t("horseTraining"),
      body: t("horseTrainingText").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Horses3,
    },
    {
   
      textOnLeft: true,
      title: t("assigningTheHorses"),
      body:  t("assigningTheHorsesText").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Horses4,
    }
  ]

  const items = [
    <BlockImage imageSrc={Horses5} />,
    <BlockImage imageSrc={Horses6} />,
    <BlockImage imageSrc={Horses7} />,
    <BlockImage imageSrc={Horses8} />,
    <BlockImage imageSrc={Horses9} />,
    <BlockImage imageSrc={Horses10} />,
    <BlockImage imageSrc={Horses11} />,

 ];
 
 
 const responsive = {
   0: { items: 1 },
   568: { items: 2 },
   1024: { items: 3, itemsFit: 'contain'},
 };
  
  return (
    <>
      <ContainerBackgorund> 
        <Container>
          <TopBlock imageSrc={TopImage} title={t("horses").toUpperCase()}/>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSubtitle>{t("theHerd").toUpperCase()}</CustomSubtitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("horsesTopDescription")}</CustomParagraphWithPX>
          <CustomSpaceContainer></CustomSpaceContainer>
          <Blocks blocks={topTwoBlocks}/>

          <CustomSubtitle>{t("ourHorses").toUpperCase()}</CustomSubtitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("ourHorsesBody").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</CustomParagraphWithPX>
          <Blocks blocks={bottomTwoBlocks}/>
          <center>
            <GlobalCarousel items={items} />
          </center>
          <CustomSpaceContainer></CustomSpaceContainer>
          <BookContainer></BookContainer>
          <CustomSpaceContainer></CustomSpaceContainer>
        </Container>
      </ContainerBackgorund> 
    </>
  )
    
  }

  


