import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import Blocks from "components/features/VerticalWithAlternateImageAndText.js"
import GlobalCarousel from "components/features/GlobalCarousel.js";
import 'react-alice-carousel/lib/alice-carousel.css';
import TopBlock from "components/features/TopBlock.js";
import BookContainer from "components/features/BookContainer.js";
import Accomodation1 from "../images/Accomodation/Accomodation1.webp";
import Accomodation2 from "../images/Accomodation/Accomodation2.webp";
import Accomodation3 from "../images/Accomodation/Accomodation3.webp";
import Accomodation4 from "../images/Accomodation/Accomodation4.webp";
import Accomodation5 from "../images/Accomodation/Accomodation5.webp";
import Accomodation6 from "../images/Accomodation/Accomodation6.webp";
import Accomodation7 from "../images/Accomodation/Accomodation7.webp";
import Accomodation8 from "../images/Accomodation/Accomodation8.webp";
import Accomodation9 from "../images/Accomodation/Accomodation9.webp";
import Accomodation10 from "../images/Accomodation/Accomodation10.webp";
import Accomodation11 from "../images/Accomodation/Accomodation11.webp";
import Accomodation12 from "../images/Accomodation/Accomodation12.webp";
import {CustomSubtitle, CustomParagraphWithPX, CustomSpaceContainerMobile, CustomSpaceContainerXs, CustomSpaceContainer, CustomSpaceContainerXl, CustomSpaceContainer2Xl, CustomSpaceContainer3Xl} from "components/Common.js";
import { BlockImage } from "components/misc/BlockImage";



const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`
const Container = tw.div`relative`;



export default function Accomodation() {
  const { t } = useTranslation();
  const handleDragStart = (e) => e.preventDefault();

  const blocks = [
    {
      textOnLeft: true,
      title: t("accomodationPageBlock1Title"),
      body: t("accomodationPageBlock1Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Accomodation3,
    },
    {
   
      textOnLeft: true,
      title: t("accomodationPageBlock2Title"),
      body:  t("accomodationPageBlock2Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Accomodation4,
    },
  
  ]

  const blocks2 = [
    {
      textOnLeft: true,
      title: t("accomodationPageBlock3Title"),
      body: t("accomodationPageBlock3Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Accomodation6,
    },
   
  
  ]


  const blocks3 = [
    {
      textOnLeft: false,
      title: t("accomodationPageBlock4Title"),
      body: t("accomodationPageBlock4Body"),
      imagePath: Accomodation11,
    },
   
  
  ]

  const items = [
     <BlockImage imageSrc={Accomodation7} />,
     <BlockImage imageSrc={Accomodation8} />,
     <BlockImage imageSrc={Accomodation9} />,
     <BlockImage imageSrc={Accomodation10} />,

  ];
  
  
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3, itemsFit: 'contain'},
  };
  
  return (
    <>
      <ContainerBackgorund> 
        <Container>
          <TopBlock imageSrc={Accomodation1} title={t("accomodations").toUpperCase()} textColor={'#000'}/>
          <CustomSpaceContainer></CustomSpaceContainer>
          <TopBlock imageSrc={Accomodation2} title={t("guestSpaces").toUpperCase()} height={'685px'} textColor={'#000'}/>
          <Blocks blocks={blocks}/>
          <TopBlock imageSrc={Accomodation5} title={t("accomodationPageBlock3Title").toUpperCase()} textColor={'#000'}/>
          <Blocks blocks={blocks2}/>
          <center>
            <GlobalCarousel items={items}/>
          </center>
          <Blocks blocks={blocks3}/>
          <TopBlock imageSrc={Accomodation12} />
          <CustomSpaceContainer></CustomSpaceContainer>
          <BookContainer></BookContainer>
          <CustomSpaceContainer></CustomSpaceContainer>
        </Container>
      </ContainerBackgorund>
    </>
  )
    
  }

  


