import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import GlobalCarouselTexts from "components/features/GlobalCarouselTexts.js";
import GlobalCarousel from "components/features/GlobalCarousel.js";
import 'react-alice-carousel/lib/alice-carousel.css';
import BookContainer from "components/features/BookContainer.js";
import TopBlock from "components/features/TopBlock.js";
import Ranch1 from "../images/Ranch/Ranch1.webp";
import Ranch2 from "../images/Ranch/Ranch2.webp";
import Ranch3 from "../images/Ranch/Ranch3.webp";
import Ranch4 from "../images/Ranch/Ranch4.webp";
import Ranch5 from "../images/Ranch/Ranch5.webp";
import Ranch6 from "../images/Ranch/Ranch6.webp";
import Ranch7 from "../images/Ranch/Ranch7.webp";
import Ranch8 from "../images/Ranch/Ranch8.webp";
import Ranch9 from "../images/Ranch/Ranch9.webp";
import Ranch10 from "../images/Ranch/Ranch10.webp";
import buttonBckground from  "images/buttonBackground.svg";
import HeadingTitle from "components/features/HeadingTitle";

import {CustomSubtitle, CustomParagraphWithPX, CustomSpaceContainer, CustomTitle} from "components/Common.js";
import { BlockImage } from "components/misc/BlockImage";

const BlockHeading = styled.h2`${tw` text-text-color text-sm text-center 
  px-10 pt-5`}
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 80`;


const Text = styled.h2`${tw` text-text-color text-xl text-center 
px-10 lg:px-32 xl:px-56 2xl:px-56 md:px-10 sm:px-10   `}
font-style: normal;
font-weight: 80`;
const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`
const Container = tw.div`relative`;


const SubheadingTitle = styled.p`${tw`text-center text-4xl xxs:text-2xl xs:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl md:text-3xl sm:text-3xl text-text-color mx-auto 
pt-10 xxs:pt-5 px-56 2xl:px-56 xl:px-56 lg:px-40 md:px-20 sm:px-20 xs:px-20 xxs:px-10`}
font-family: 'Ultra';
font-weight: 300;
font-style: normal;`;

const Image = tw.img`object-cover w-full`
const BlockText = tw.p`text-center font-medium leading-relaxed text-secondary-100 mt-1 text-text-color`;

const ButtonImage = styled.div(props => [
  `background-image: url("${buttonBckground}");`,
  tw`h-70 w-full pt-2 
 bg-no-repeat bg-center `
]);

const ContainerCartesian =  tw.div`grid grid-cols-2 gap-0 items-center justify-center 
p-20 xxs:p-10 xs:p-10 sm:p-20 md:p-20 lg:p-20 xl:p-20 2xl:p-20
`;
const gridTest = tw.div`bg-green-600 text-black border border-2 border-black`;


const TopLeftContainer = styled.p`${tw`text-4xl xxs:text-2xl xs:text-2xl sm:text-3xl md:text-4xl
h-56 xxs:h-48 xs:h-48 sm:h-48 md:h-48 lg:h-48 xl:h-56 2xl:h-56
text-black w-full border-b border-black border-r flex flex-col justify-center items-center text-center`}
font-family: 'Ultra';
font-weight: 400;
font-style: normal;`;

const TopRightContainer = styled.p`${tw`text-4xl xxs:text-2xl xs:text-2xl sm:text-3xl md:text-4xl
h-56 xxs:h-48 xs:h-48 sm:h-48 md:h-48 lg:h-48 xl:h-56 2xl:h-56
text-black w-full border-b border-black border-l flex flex-col justify-center items-center text-center`}
font-family: 'Ultra';
font-weight: 400;
font-style: normal;`;

const BottomLeftContainer = styled.p`${tw`text-4xl xxs:text-2xl xs:text-2xl sm:text-3xl md:text-4xl
h-56 xxs:h-48 xs:h-48 sm:h-48 md:h-48 lg:h-48 xl:h-56 2xl:h-56
text-black w-full border-t border-black border-r flex flex-col justify-center items-center text-center`}
font-family: 'Ultra';
font-weight: 400;
font-style: normal;`;

const BottomRightContainer = styled.p`${tw`text-4xl xxs:text-2xl xs:text-2xl sm:text-3xl md:text-4xl
h-56 xxs:h-48 xs:h-48 sm:h-48 md:h-48 lg:h-48 xl:h-56 2xl:h-56
text-black w-full border-t border-black border-l flex flex-col justify-center items-center text-center`}
font-family: 'Ultra';
font-weight: 400;
font-style: normal;`;

const SpaceContainer = tw.div`
relative h-50 3xs:h-px xxs:h-px xs:h-px lg:h-50 xl:h-50 2xl:h-50 md:h-50 sm:h-px
`;
const SpaceContainerMobile = tw.div`
relative 
h-0 xxs:h-10 xs:h-16 sm:h-16 md:h-0 lg:h-0 2xl:h-0
`;

const SpaceContainerXl = tw.div`
relative 
h-0 xxs:h-10 xs:h-16 sm:h-16 md:h-0 lg:h-10 2xl:h-20
`;


export default function Ranch() {
  const { t } = useTranslation();
  const handleDragStart = (e) => e.preventDefault();

  const items = [
     <BlockImage imageSrc={Ranch3} />,
     <BlockImage imageSrc={Ranch4} />,
     <BlockImage imageSrc={Ranch5} />,
     <BlockImage imageSrc={Ranch6} />,
     <BlockImage imageSrc={Ranch7} />,
     <BlockImage imageSrc={Ranch8} />,
     <BlockImage imageSrc={Ranch9} />,

  ];

  const textBreakPoints = 
[
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1},
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 3},
  { width: 1450, itemsToShow: 3 },
  { width: 1750, itemsToShow: 3 },
]
  
  
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3, itemsFit: 'contain'},
  };

  const texts = [
    <>
    <Container>
    <ButtonImage>
            <BlockHeading>{t("aprilJune").toUpperCase()}</BlockHeading>
            </ButtonImage>
           

           <BlockText>{ t("aprilJuneBlock").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</BlockText>
    </Container>
            
    </>,<>
    <Container>
    <ButtonImage>
            <BlockHeading>{t("julyAugust").toUpperCase()}</BlockHeading>
            </ButtonImage>
           <BlockText>{ t("julyAugustBlock").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</BlockText>
    </Container>
   ,
    </>,<>
    <Container>
    <ButtonImage>
            <BlockHeading>{t("septemberOctober").toUpperCase()}</BlockHeading>
            </ButtonImage>
           <BlockText>{ t("septemberOctoberBlock").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</BlockText>
    </Container>
   ,
    </>,
  ];

  return (
    <>
      <ContainerBackgorund> 
        <Container>
        <TopBlock imageSrc={Ranch1} title={t("theRanch").toUpperCase()}/>
        <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSubtitle>{t("locatedIn").toUpperCase()}</CustomSubtitle>
          <CustomTitle>{t("Wyoming").toUpperCase()}</CustomTitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("ranchText1").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</CustomParagraphWithPX>
          <CustomSpaceContainer></CustomSpaceContainer>
          <TopBlock imageSrc={Ranch2}/>
          <ContainerCartesian>
            <TopLeftContainer>{t("rideAcres")}</TopLeftContainer>
            <TopRightContainer>{t("horses100")}</TopRightContainer>
            <BottomLeftContainer>{t("cattleBreedingProgram")}</BottomLeftContainer>
            <BottomRightContainer>{t("horseBreedingProgram")}</BottomRightContainer>
          </ContainerCartesian>
          <GlobalCarousel items={items}/>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSubtitle>{t("yourAdventureBeginsHere")}</CustomSubtitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("yourAdventureBeginsHereText").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</CustomParagraphWithPX>
          <CustomSpaceContainer></CustomSpaceContainer>
          <TopBlock imageSrc={Ranch10}/>
          <CustomSpaceContainer></CustomSpaceContainer>
          <center>
            <GlobalCarouselTexts  items={texts} breakPoints={textBreakPoints}/>
          </center>
          
          <BookContainer></BookContainer>
          <CustomSpaceContainer></CustomSpaceContainer>
        </Container>
      </ContainerBackgorund> 
    </>
  )
    
  }

  


