import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useTranslation } from 'react-i18next';
import { SectionHeading } from "../misc/Headings.js";
import ThreeColSimple from "components/features/ThreeColSimple.js";
import Background from  "images/bookNowBackground.svg";
import BookNowButtonBackground from  "images/bookNowButtonBackground.svg"

const CustomSubtitle = styled.p`${tw`text-center 
xxs:text-lg xs:text-xl sm:text-xl md:text-xl lg:text-2xl xl:text-3xl 2xl:text-3xl
px-10 xxs:px-0 text-center 
py-0 xxs:py-5
text-white
mx-auto `}
font-family: 'Ultra';
font-style: normal;
line-height: 22px`;


const Container = tw.div`relative mr-5 ml-5
h-72 xxs:h-72
bg-brown-custom
flex flex-col justify-center items-center 
px-72 xxs:px-5 xs:px-10 sm:px-10 md:px-10 lg:px-20 2xl:px-72
`;

const TopTitle = styled.h2`${tw` text-white 
text-4xl xxs:text-sm xs:text-xl sm:text-2xl md:text-2xl lg:text-3xl 2xl:text-4xl
text-center 
pt-0 xxs:pt-5 xs:pt-5 2xl:pt-5
pb-20 xxs:pb-0 xs:pb-0 sm:pb-2 md:pb-5 lg:pb-10 2xl:pb-5 
`}
font-family: 'Ultra';
font-style: normal;
font-weight: 80`;
const ButtonText = styled.h2`${tw` text-text-color text-lg text-center 
  px-10 `}
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 80`;

const ButtonImage = styled.div(props => [
    `background-image: url("${BookNowButtonBackground}");`,
    tw`h-10 pt-2
    bg-contain bg-no-repeat bg-center `
  ]);

const Button = tw.button`w-full sm:w-auto mt-6 sm:mt-0 py-4 text-black text-center`
const Image = styled.div(props => [
  `background-image: ur("${Background}");`,
  tw` 
  // pt-20 xxs:pt-5 xs:pt-5 sm:pt-10 md:pt-20 lg:pt-20 2xl:pt-20
  bg-contain bg-no-repeat bg-center 
  w-full
  bg-green-300
  h-100 xxs:h-64`
]);


export default ({link='bookNow'}) => {

  const { t } = useTranslation();
 
  
  return (
    
      // <Image>
        <Container>



<CustomSubtitle>{t("bookContainerText").toUpperCase().split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</CustomSubtitle>  


          {/* <PrimaryButton>Book now</PrimaryButton> */}
            <Button as="a" href={link}>
              <ButtonImage >
                            <ButtonText>
                            {t("bookNow").toUpperCase()} 
                            </ButtonText>
                            </ButtonImage>
                           </Button>
        </Container>
      // </Image>
    
  );
};