import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import GlobalCarousel from "components/features/GlobalCarousel.js";
import 'react-alice-carousel/lib/alice-carousel.css';
import TopBlock from "components/features/TopBlock.js";
import Blocks from "components/features/VerticalWithAlternateImageAndText.js"
import ContainerInfo from "components/features/ContainerInfo.js"
import BookContainer from "components/features/BookContainer.js";
import HeadingTitle from "components/features/HeadingTitle";

import Tour1 from "../images/Tour/Tour1.webp";
import Tour2 from "../images/Tour/Tour2.webp";
import Tour3 from "../images/Tour/Tour3.webp";
import Tour4 from "../images/Tour/Tour4.webp";
import Tour5 from "../images/Tour/Tour5.webp";
import Tour6 from "../images/Tour/Tour6.webp";
import Tour7 from "../images/Tour/Tour7.webp";
import Tour8 from "../images/Tour/Tour8.webp";
import Tour9 from "../images/Tour/Tour9.webp";
import Tour10 from "../images/Tour/Tour10.webp";
import Tour11 from "../images/Tour/Tour11.webp";
import Tour12 from "../images/Tour/Tour12.webp";
import Tour13 from "../images/Tour/Tour13.webp";
import Tour14 from "../images/Tour/Tour14.webp";
import Tour15 from "../images/Tour/Tour15.webp";
import Tour16 from "../images/Tour/Tour16.webp";
import Tour17 from "../images/Tour/Tour17.webp";
import Tour18 from "../images/Tour/Tour18.webp";
import Tour19 from "../images/Tour/Tour19.webp";
import Tour20 from "../images/Tour/Tour20.webp";
import Tour21 from "../images/Tour/Tour21.webp";
import Tour22 from "../images/Tour/Tour22.webp";
import Tour23 from "../images/Tour/Tour23.webp";
import Tour24 from "../images/Tour/Tour24.webp";
import Tour25 from "../images/Tour/Tour25.webp";
import Tour26 from "../images/Tour/Tour26.webp";
import Tour27 from "../images/Tour/Tour27.webp";
import Tour28 from "../images/Tour/Tour29.webp";
import Tour29 from "../images/Tour/Tour28.webp";
import Tour30 from "../images/Tour/Tour30.webp";
import Tour31 from "../images/Tour/Tour31.webp";
import Tour32 from "../images/Tour/Tour32.webp";
import Tour33 from "../images/Tour/Tour33.webp";
import Tour34 from "../images/Tour/Tour34.webp";
import Tour35 from "../images/Tour/Tour35.webp";
import Tour36 from "../images/Tour/Tour36.webp";
import TourText1 from "../images/Tour/TourText1.webp";
import TourText2 from "../images/Tour/TourText2.webp";
import TourText3 from "../images/Tour/TourText3.webp";
import TourText4 from "../images/Tour/TourText4.webp";
import TourText5 from "../images/Tour/TourText5.webp";
import TourText6 from "../images/Tour/TourText6.webp";
import { BlockImage } from "components/misc/BlockImage";
import Carousel from 'react-carousel-elasticss';

import {CustomSubtitle, CustomParagraphWithPX, CustomSpaceContainerMobile, CustomSpaceContainerXs, CustomSpaceContainer, CustomSpaceContainerXl, CustomSpaceContainer2Xl, CustomSpaceContainer3Xl, CustomParagraph} from "components/Common.js";


const ContainerCarousel = tw.div`
px-2
`;


const SubheadingTitle = styled.p`${tw`text-center 
text-2xl xxs:text-lg xs:text-xl sm:text-xl md:text-lg lg:text-lg xl:text-xl 2xl:text-xl
 text-text-color mx-auto 
pt-10 xxs:pt-5 px-56 2xl:px-56 xl:px-56 lg:px-40 md:px-20 sm:px-20 xs:px-20 xxs:px-10`}
// font-family: 'Ultra';
font-weight: 400;
font-style: normal;`;

const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`
const Container = tw.div`relative`;

const BlockImageSmall = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-56 ml-5 mr-5
  bg-cover bg-no-repeat bg-center `
]);



const BlockTextImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-50pp w-100 
  3xs:h-40 xxs:h-40 xs:h-40 lg:h-40 xl:h-48 2xl:h-50pp md:h-50pp sm:h-50pp 
  3xs:w-80 xxs:w-80 xs:w-80 lg:w-80 xl:w-97 2xl:w-99 md:w-100 sm:w-100


  
  bg-contain bg-no-repeat`
]);


const Image = tw.img`object-cover w-full`

export default function Tours() {
  const { t } = useTranslation();
  const handleDragStart = (e) => e.preventDefault();


  const blocks = [
    {
      textOnLeft: true,
      title: t("toursPageBlock1Title"),
      body:  t("toursPageBlock1Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Tour2,
      note: t("toursPageBlock1Note"),
    },
  ]

  const blocks2 = [
    {
      textOnLeft: true,
      title: t("toursPageBlock2Title"),
      body:  t("toursPageBlock2Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Tour8,
      note: t("toursPageBlock2Note").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),    },
  ]


  const [selectedPhoto, setPhoto] = useState(0);

  const items = [
    <div style={{opacity: selectedPhoto == 0 ? 1 : 0.5}}>
 <button style={{background:'none', border:'none'}} onClick={() => setPhoto(0)}> 
      <BlockTextImage imageSrc={TourText1} />
    </button>
    </div>,
   <div style={{opacity: selectedPhoto == 1 ? 1 : 0.5}}>
<button style={{background:'none', border:'none'}} onClick={() => setPhoto(1)}>
      <BlockTextImage imageSrc={TourText2} />
    </button>
   </div>,
    <div style={{opacity: selectedPhoto == 2 ? 1 : 0.5}}>
    <button style={{background:'none', border:'none'}} onClick={() => setPhoto(2)}>
      <BlockTextImage imageSrc={TourText3} />
    </button>
    </div>,
    <div style={{opacity: selectedPhoto == 3 ? 1 : 0.5}}>
    <button style={{background:'none', border:'none'}} onClick={() => setPhoto(3)}>
      <BlockTextImage imageSrc={TourText4} />
    </button>
     </div>,
     <div style={{opacity: selectedPhoto == 4 ? 1 : 0.5}}>
    <button style={{background:'none', border:'none'}} onClick={() => setPhoto(4)}>
      <BlockTextImage imageSrc={TourText5}/>
    </button>
    </div>,
    <div style={{opacity: selectedPhoto == 5 ? 1 : 0.5}}>
    <button style={{background:'none', border:'none'}} onClick={() => setPhoto(5)}>
      <BlockTextImage imageSrc={TourText6}/>
    </button>
    </div>

  ];
  
  
  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 4, itemsFit: 'contain'},
  };

  const items2 = [
    <BlockImage imageSrc={Tour9} />,
    <BlockImage imageSrc={Tour10} />,
    <BlockImage imageSrc={Tour11} />,
    <BlockImage imageSrc={Tour12} />,
    <BlockImage imageSrc={Tour13}/>,
    <BlockImage imageSrc={Tour14}/>,
    <BlockImage imageSrc={Tour15}/>,
 ];

  const responsive2 = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2, itemsFit: 'contain'},
  };

  const blocks3 = [
    {
      textOnLeft: false,
      title: t("toursPageBlock3Title"),
      body:  t("toursPageBlock3Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Tour17,
    },
    {
      title: t("toursPageBlock4Title"),
      body:  t("toursPageBlock4Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Tour18,
    },
  ]
  
  const items3 = [
    <BlockImage imageSrc={Tour19} />,
    <BlockImage imageSrc={Tour20} />,
    <BlockImage imageSrc={Tour21} />,
    <BlockImage imageSrc={Tour22} />,
    <BlockImage imageSrc={Tour23} />,
    <BlockImage imageSrc={Tour24} />,
    <BlockImage imageSrc={Tour25} />,
    <BlockImage imageSrc={Tour26} />,

 ];

  const responsive3 = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2, itemsFit: 'contain'},
  };

  const blocks4 = [
    {
      textOnLeft: false,
      title: t("toursPageBlock5Title"),
      body:  t("toursPageBlock5Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Tour29,
      note:  t("toursPageBlock5Note").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
    },
    {
      title: t("toursPageBlock6Title"),
      body:  t("toursPageBlock6Body").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      imagePath: Tour28,
    },
  ]

  const items4 = [
    <BlockImage imageSrc={Tour30} />,
    <BlockImage imageSrc={Tour31} />,
    <BlockImage imageSrc={Tour32} />,
    <BlockImage imageSrc={Tour33} />,
    <BlockImage imageSrc={Tour34} />,
    <BlockImage imageSrc={Tour35} />,
    <BlockImage imageSrc={Tour36} />,

 ];

 const breakPoints=[
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1},
  { width: 850, itemsToShow: 2 },
  { width: 1070, itemsToShow: 2 },
  { width: 1150, itemsToShow: 3},
  { width: 1450, itemsToShow: 3 },
  { width: 1750, itemsToShow: 3 },
]

  const responsive4 = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 2, itemsFit: 'contain'},
  };


  const test = 
    {
      "duration": selectedPhoto == 0 ? t("devilsTowerToursDuration"): selectedPhoto == 1 ? t("mountRashmoreTourDuration") : selectedPhoto == 2 ? t("crazyHorseTourDuration") :  selectedPhoto == 3 ? t("rapidCityTourDuration") : selectedPhoto == 4 ? t("deadwoodTourDuration") :  t("badlandsTourDuration"),
      "distance": selectedPhoto == 0 ? t("devilsTowerToursDistance"): selectedPhoto == 1 ? t("mountRashmoreTourDistance") : selectedPhoto == 2 ? t("crazyHorseTourDistance") :  selectedPhoto == 3 ? t("rapidCityTourDistance") : selectedPhoto == 4 ? t("deadwoodTourDistance") :  t("badlandsTourDistance"),
      "features": selectedPhoto == 0 ? t("devilsTowerToursFeatures"): selectedPhoto == 1 ? t("mountRashmoreTourFeatures") : selectedPhoto == 2 ? t("crazyHorseTourFeatures") :  selectedPhoto == 3 ? t("rapidCityTourFeatures") : selectedPhoto == 4 ? t("deadwoodTourFeatures") :  t("badlandsTourFeatures"), 
      "dx": selectedPhoto == 0 ? t("devilsTowerTours"): selectedPhoto == 1 ? t("mountRashmoreTour") : selectedPhoto == 2 ? t("crazyHorseTour") :  selectedPhoto == 3 ? t("rapidCityTour") : selectedPhoto == 5 ? t("deadwoodTour") :  t("badlandsTour")
    }

  
 

  


  return (
    
    <>
      <ContainerBackgorund> 
        <Container>
        <TopBlock imageSrc={Tour1} title={t("tours").toUpperCase()} height={'750px'}/>
          <Blocks blocks={blocks}/>
          <center>
       <ContainerCarousel>
       <Carousel  breakPoints={breakPoints} showArrows={true} enableAutoPlay={false} onResize={currentBreakPoint => console.log(currentBreakPoint)} renderPagination={({ pages, activePage, onClick }) => {
              return (
                <Container>
                </Container>
              )
            }} onChange={({item, index})=>{
              if (window.innerWidth <= 850) {
                setPhoto(index)
              }
            }}
          >{items}</Carousel> 
       </ContainerCarousel>
          </center>
          <ContainerInfo blocks={test}/>
          <TopBlock imageSrc={Tour7} height={'916px'} title={t("rodeoExperience").toUpperCase()}/>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("rodeoExperienceText")}</CustomParagraphWithPX>
          <Blocks blocks={blocks2}/>
          <center>
            <GlobalCarousel  items={items2} />
          </center>
          <CustomSpaceContainer></CustomSpaceContainer>
          <TopBlock imageSrc={Tour16} height={'685px'}  title={t("theGreatParksTour").toUpperCase()}/> 
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSubtitle>{t("toursTitle1").toUpperCase()}</CustomSubtitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomParagraphWithPX>{t("toursSubTitle1").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</CustomParagraphWithPX>
          <Blocks blocks={blocks3}/>
          <SubheadingTitle>{t("toursPageText").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <CustomSpaceContainer></CustomSpaceContainer>
          <CustomSpaceContainer></CustomSpaceContainer>
          <center>
            <GlobalCarousel  items={items3}/>
          </center>
          <CustomSpaceContainer></CustomSpaceContainer>
          <TopBlock imageSrc={Tour27} height={'745px'} title={t("yellowstone").toUpperCase()}/>
          <Blocks blocks={blocks4}/>
          <center>
            <GlobalCarousel  items={items4} />
          </center>
          <CustomSpaceContainer></CustomSpaceContainer>
          <BookContainer></BookContainer>
        </Container>
        <CustomSpaceContainer></CustomSpaceContainer>
      </ContainerBackgorund> 
    </>
  )
    
  }

  


