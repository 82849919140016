import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

function HamburgerMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const handleClick = () => {
      setIsOpen(!isOpen);
    };
  
    const handleClose = () => {
      setIsOpen(false);
    };
    
  
    return (
      <div className="hamburger-menu">
        <div className="menu-icon" onClick={handleClick}>
          <div className={`menu-line ${isOpen ? "open" : ""}`}></div>
          <div className={`menu-line ${isOpen ? "open" : ""}`}></div>
          <div className={`menu-line ${isOpen ? "open" : ""}`}></div>
        </div>
      
        <div className={`menu-items ${isOpen ? "open" : ""}`}>
            var  {isOpen && (
          <button className="close-button" onClick={handleClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="#382916" d="M18.707 5.293a1 1 0 0 0-1.414 0L12 10.586 7.707 6.293a1 1 0 1 0-1.414 1.414L10.586 12l-4.293 4.293a1 1 0 0 0 1.414 1.414L12 13.414l4.293 4.293a1 1 0 0 0 1.414-1.414L13.414 12l4.293-4.293a1 1 0 0 0 0-1.414z"/></svg>
        </button>
        )}
          {isOpen && (
            <ul>
             <a as="a" href={'/'}>{t("Home")}</a>
        <a as="a" href={'the-ranch'}>{t("theRanch")}</a>
        <a as="a" href={'adventures'}>{t("adventures")}</a>
        <a as="a" href={'horses'}>{t("horses")}</a>
        <a as="a" href={'accomodations'}>{t("Accomodations")}</a>
        <a as="a" href={'tours'}>{t("Tours")}</a> 
        <a as="a" href={'contacts'}>{t("contacts")}</a> 
        <a as="a" href={'bookNow'}>{t("bookNow")}</a> 
            </ul>
          )}
        </div>
      </div>
    );
  }
  
export default HamburgerMenu;