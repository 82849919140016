/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import SampleVideo from "video/sampleVideo.mp4";
import VideoMP4 from "video/lakeranch.mp4";
import VideoMOV from "video/lakeranch.mov";

export default ({ url, background = "black", className = "video" }) => {
  const TopTitle = styled.h2`${tw` text-text-color text-6xl 3xs:text-2xl xxs:text-2xl xs:text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl 2xl:text-6xl text-center pt-10 w-full 3xs:invisible xxs:invisible xs:invisible lg:visible xl:visible 2xl:visible md:visible sm:visible`}
    font-family: 'Ultra';
    font-style: normal;
    font-weight: 80`;


  const FullVideoTitle = styled.h2`${tw` text-white text-xl 3xs:text-sm xxs:text-sm xs:text-sm sm:text-xl md:text-xl lg:text-xl xl:text-xl 2xl:text-xl text-center pb-5 w-full absolute bottom-0 drop-shadow-topBlockTitleShadow`}
    font-family: 'Ultra';
    font-style: normal;
    font-weight: 80`;

  const { t } = useTranslation();

  return (
    <div
      className={className}
      style={{
        position: "relative",
        background: background,
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      {/* <iframe
        title="Embeded Video"
        allow='autoplay'
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={url}
        frameBorder="0"
      /> */}
      <video autoPlay loop muted playsInline style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      }}>
        <source src="https://firebasestorage.googleapis.com/v0/b/lakeranch-14815.appspot.com/o/lakeranch.mp4?alt=media&token=4e37f599-5456-4e9f-bc97-e0abd0fec364" type="video/mp4" />
      </video>
      <div className="text-overlay">


      </div>
      <button onClick={() => console.log('hhh')}>
        <FullVideoTitle>{t("watchFullVideo").toUpperCase()}</FullVideoTitle>
      </button>

    </div>
  );
};
