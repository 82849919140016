import { Outlet } from 'react-router-dom';
import styled from "styled-components";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import tw from "twin.macro";
import { useTranslation } from 'react-i18next';
import FacebookLogo from 'images/facebookLogo.png'
import InstagramLogo from 'images/instagramLogo.png'

const Footer = tw.div`h-12 bg-text-color flex justify-end items-center`
const Spacer = tw.div`flex-grow`
const ButtonImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`
  h-6 w-6
  xxs:aspect-square
  bg-cover bg-no-repeat bg-center `
]);
const Layout = () => {
  const { t, i18n } = useTranslation();
  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  return (
    <>
    <Hero />
       <div className="content">
         <Outlet />
       </div>
       <Footer>
        <div style={{width: 15}} />
       <button style={{paddingRight: '15px'}} onClick={() => window.open('https://www.facebook.com/lakeranchwyoming?mibextid=LQQJ4d', '_blank')}>
          <ButtonImage imageSrc={FacebookLogo}>
          </ButtonImage>
        </button>
       <button style={{paddingRight: '15px'}} onClick={() => window.open('http://instagram.com/lake_ranch', '_blank') }>
          <ButtonImage imageSrc={InstagramLogo}>
          </ButtonImage>
        </button>
       <Spacer />
        <button style={{paddingRight: '15px', fontSize: '30px'}} onClick={() => handleTrans('en-US')}>{'🇺🇸'}</button>
        <button style={{paddingRight: '15px', fontSize: '30px'}} onClick={() => handleTrans('it_IT')}>{'🇮🇹'}</button>
        <button style={{paddingRight: '15px', fontSize: '30px'}} onClick={() => handleTrans('de_DE')}>{'🇩🇪'}</button>
        <button style={{paddingRight: '15px', fontSize: '30px'}} onClick={() => handleTrans('fr_FR')}>{'🇫🇷'}</button>
        <button style={{paddingRight: '15px', fontSize: '30px'}} onClick={() => handleTrans('nl_NL')}>{'🇱🇺'}</button>
        <button style={{paddingRight: '15px', fontSize: '30px'}} onClick={() => handleTrans('es_EM')}>{'🇪🇸'}</button>
        <button style={{paddingRight: '15px', fontSize: '30px'}} onClick={() => handleTrans('pt_PT')}>{'🇵🇹'}</button>
      </Footer>
    </>
  );
};

export default Layout;
