import { Routes, Route } from 'react-router-dom';
import Accomodations from '../routes/Accomodations';
import Adventures from '../routes/Adventures';
import Tours from '../routes/Tours';
import Ranch from '../routes/Ranch';
import Horses from '../routes/Horses';
import Contacts from '../routes/Contacts';
import BookNow from '../routes/BookNow';

import Home from '../routes/Home';
import Layout from './Layout';
import GlobalStyles from '../styles/GlobalStyles';

const App = () => {

  return (
    <>
      <GlobalStyles />
        <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="horses" element={<Horses />} />
          <Route path="bookNow" element={<BookNow />} />
          <Route path="accomodations" element={<Accomodations />} />
          <Route path="adventures" element={<Adventures />} />
          <Route path="tours" element={<Tours />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="the-ranch" element={<Ranch />} />
          <Route path="*" element={<Layout />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
