import styled from "styled-components";
import tw from "twin.macro";
import { useTranslation } from 'react-i18next';

const Container = tw.div`relative`;
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 content-around`;
const Content = tw.div`mt-16`;

const TopTitle = styled.h2(props => [
  `font-family: 'Ultra'; font-style: normal; font-weight: 80; color: ${props.textColor};`,
  tw`
  drop-shadow-topBlockTitleShadow
  text-6xl xxs:text-3xl xs:text-4xl sm:text-5xl md:text-5xl lg:text-6xl 2xl:text-6xl
  text-center 
  px-10 xxs:px-0 sm:px-10 md:px-10  lg:px-32 xl:px-56 2xl:px-56
  pt-10`
])


const CenterTitle = styled.h2`${tw`text-white text-2xl text-center 
drop-shadow-topBlockTitleShadow
px-10 sm:px-10 md:px-10 lg:px-32 xl:px-56 2xl:px-56 flex justify-center items-center`}
font-family: 'Ultra';
font-style: normal;
font-weight: 80`

const TopImageBlock = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`
    h-144 xxs:h-80 xs:h-100 sm:h-144 md:h-178 lg:h-200 xl:h-200 2xl:h-200

    w-full
    xxs:aspect-square
    bg-cover bg-no-repeat bg-center `
  ]);


export default ({imageSrc = [{}], title ='', height = '36rem', width = 'none', center = false, textColor = '#000'
}) => {

  const { t } = useTranslation();
  
  // style={{height: height, width: width,}}
  return (
    <TopImageBlock imageSrc={imageSrc} height={height}  width={width}>
      {center == true && 
      <CenterTitle style={{height: height}}>{title.toUpperCase()}</CenterTitle>}
      {center == false && 
      <TopTitle textColor={textColor}>{title.toUpperCase()}</TopTitle>}
      </TopImageBlock>
  );
};