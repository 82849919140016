import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useTranslation } from 'react-i18next';
import { SectionHeading } from "../misc/Headings.js";
import buttonBckground from  "images/buttonBackground.svg";
import Background from  "images/bookNowBackground.svg";

const ButtonImage = styled.div(props => [
  `background-image: url("${Background}");`,
  tw`h-10 pt-2 
  bg-contain bg-no-repeat bg-center `
]);
const SpaceContainerMobile = tw.div`
relative 
h-16 3xs:h-10 xxs:h-10 xs:h-10 sm:h-16 md:h-16 lg:h-16 2xl:h-16
`;
const BookNowButtonMobileContainer = tw.div`visible 3xs:visible xxs:visible xs:visible lg:hidden xl:hidden 2xl:hidden md:hidden sm:hidden pt-10`;

const BookNowButtonDesktopContainer = tw.div`h-0 lg:h-10 xl:h-10 2xl:h-10 md:h-10 sm:h-10 3xs:h-0 xxs:h-0 xs:h-10 
w-full grid grid-cols-3 visible lg:visible xl:visible 2xl:visible md:visible sm:visible 3xs:invisible xxs:invisible xs:invisible `;
const ButtonText = styled.h2`${tw` text-white text-xs text-center 
3xs:px-10 xxs:px-10 xs:px-10 lg:px-0 xl:px-0 2xl:px-0 md:px-0 sm:px-0 3xs:pt-1 xxs:pt-1 xs:pt-1 lg:pt-3 xl:pt-3 2xl:pt-3 md:pt-3 sm:pt-3 `}
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 60`;
const Button = tw.button`text-black text-center bg-text-color h-10`
const SingleColumn = tw.div`max-w-screen-xl mx-auto content-around`;
const Content = tw.div`mt-0`;
const PrimaryButton = tw.button`font-bold`;
const Card = styled.div(props => [
  tw`md:flex justify-center  flex-row mt-0`,
 ,
]);
const Container = tw.div`grid grid-cols-2 xxs:grid-cols-1 xs:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 3xs:grid-cols-2 2xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 
 gap-4 place-content-around `;
const Details = tw.div`mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8 flex flex-col justify-center items-center`;
const SpaceContainer = tw.div`relative h-50 3xs:h-px xxs:h-px xs:h-px lg:h-50 xl:h-50 2xl:h-50 md:h-50 sm:h-px`;

const TextContent = tw.div``;
const Heading = styled(SectionHeading)`${tw`text-left 3xs:text-center
xxs:text-center xs:text-center lg:text-left  xl:text-left  2xl:text-left sm:text-left
text-lg leading-tight text-text-color`};
font-family: 'ultra';
font-style: normal;
font-weight: 800;`

const SpacerForButton = tw.div`pb-10 lg:pb-10 xl:pb-10 2xl:pb-10 sm:pb-10 3xs:pb-5 xxs:pb-5 xs:pb-5`;

const SubDescription = tw.p`text-center font-medium leading-relaxed text-secondary-100 3xs:mt-0 xxs:mt-0  xs:mt-0 lg:mt-5 xl:mt-5 2xl:mt-5 md:mt-5 sm:mt-5 text-text-color`

export default ({blocks = {}
}) => {

  const { t } = useTranslation();

  return (

    <Container>
      <SingleColumn>
        <Content>
        <SpaceContainerMobile />
            <>
              <Card>
                <Details>
                  <TextContent>
                    <Heading key={"duration"}>{t("duration") + " " + blocks['duration']}</Heading>
                    <div style={{height: '2.5rem'}}> </div>
                    <Heading key={"distance"}>{t("distance") + " " + blocks['distance']}</Heading>
                    <div style={{height: '2.5rem'}}> </div>
                    <Heading key={"features"}>{t("features") +  " " + blocks['features']}</Heading>
                    <SpacerForButton></SpacerForButton>
                  </TextContent>
                  <BookNowButtonDesktopContainer>
                  <Button as="a" href={'bookNow'}>
                       
                            <ButtonText>
                            {t("bookNow").toUpperCase()} 
                            </ButtonText>
                        
                      </Button>
                    </BookNowButtonDesktopContainer>
                     
                </Details>
              </Card>
            </>
        </Content>
      </SingleColumn>
      <Details>
        <TextContent>
           <SubDescription>{blocks['dx']}</SubDescription>
              </TextContent>
                <BookNowButtonMobileContainer>
                  
                    <ButtonImage >
                        <ButtonText>
                          {t("bookNow").toUpperCase()} 
                        </ButtonText>
                    </ButtonImage>
                  
                </BookNowButtonMobileContainer>
        </Details>
              <SpaceContainerMobile/>
    </Container>
   
  );
};