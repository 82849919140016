import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import GlobalCarousel from "components/features/GlobalCarousel.js";
import 'react-alice-carousel/lib/alice-carousel.css';
import TopImage from "../images/Contacts/ContactsTopImage.webp";
import MapView from "../images/Contacts/mapView.webp";
import TopBlock from "components/features/TopBlock.js";
import Blocks from "components/features/VerticalWithAlternateImageAndText.js";
import Contacts1 from "../images/Contacts/Contacts1.webp";
import Contacts2 from "../images/Contacts/Contacts2.webp";
import Contacts3 from "../images/Contacts/Contacts3.webp";
import Contacts4 from "../images/Contacts/Contacts4.webp";
import Contacts5 from "../images/Contacts/Contacts5.webp";
import Contacts6 from "../images/Contacts/Contacts6.webp";
import BookContainer from "components/features/BookContainer.js";
import {CustomSpaceContainer, CustomSpaceContainer2Xl} from "components/Common.js";
import { BlockImage } from "components/misc/BlockImage";


const Heading = styled.h2`${tw` text-text-color text-xl text-center 
px-10 lg:px-32 xl:px-56 2xl:px-56 md:px-10 sm:px-10   `}
font-family: 'Ultra';
font-style: normal;
font-weight: 80`;

const TopTitle = styled.h2`${tw` text-text-color text-6xl text-center 
px-10 lg:px-32 xl:px-56 2xl:px-56 md:px-10 sm:px-10  pt-10 `}
font-family: 'Ultra';
font-style: normal;
font-weight: 80`;
const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`
const Container = tw.div`relative`;



const TopImageBlock = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`h-144
    bg-cover bg-no-repeat bg-center `
  ]);

const SubheadingTitle = styled.p`${tw`text-center text-4xl xxs:text-2xl xs:text-3xl lg:text-4xl xl:text-4xl 2xl:text-4xl md:text-3xl sm:text-3xl text-text-color mx-auto 
pt-10 xxs:pt-5 px-56 2xl:px-56 xl:px-56 lg:px-40 md:px-20 sm:px-20 xs:px-20 xxs:px-10`}
font-family: 'Amiko';
font-weight: 300;
font-style: normal;`;

export default function Adventures() {
  const { t } = useTranslation();
  const handleDragStart = (e) => e.preventDefault();

  
  
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3, itemsFit: 'contain'},
  };




  const topBlock = [
    {
      textOnLeft: true,
      title: t("lakeRanchCompany"),
      body: '314 Lake Ranch Road\nHulett,WY 82720 U.S.A.\n\n+1 (307) 467-5908\nlakeranch@lakeranch.com\n\nFerdinando Mastrovito\ncell USA +1 (307) 290-2025\ncell + 39 392 396 2873\n\nRoberto Capitani\ncell USA +1 (307) 290-0411\ncell +39 339 855 2709 '.split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>)),
      alignLeft: true,
      imagePath: MapView,
     
    },
    
  ]

  const items3 = [
    <BlockImage imageSrc={Contacts1} />,
    <BlockImage imageSrc={Contacts2} />,
    <BlockImage imageSrc={Contacts3} />,
    <BlockImage imageSrc={Contacts4} />,
    <BlockImage imageSrc={Contacts5} />,
    <BlockImage imageSrc={Contacts6} />,

 ];

 const responsive3 = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3, itemsFit: 'contain'},
};
  
  return (
    <>
    <ContainerBackgorund> 
    <Container>
    <TopBlock imageSrc={TopImage} title={t("contacts").toUpperCase()} height={'800px'} textColor={'#FFF'}/>
    <Blocks blocks={topBlock}/>
    <center>
      <GlobalCarousel items={items3} />
    </center>
    <CustomSpaceContainer2Xl></CustomSpaceContainer2Xl>
    <BookContainer></BookContainer>
</Container>
<CustomSpaceContainer></CustomSpaceContainer>

</ContainerBackgorund> 






    </>
  )
    
  }

  


