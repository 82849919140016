import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import BookContainer from "components/features/BookContainer.js";
import TopBlock from "components/features/TopBlock.js";
import Booking1 from "../images/Booking/Booking1.webp";
import HeadingTitle from "components/features/HeadingTitle";

const TopTitle = styled.h2`${tw` text-text-color text-6xl text-center 
px-10 lg:px-32 xl:px-56 2xl:px-56 md:px-10 sm:px-10  pt-10 `}
font-family: 'Ultra';
font-style: normal;
font-weight: 80`;

const BodyTitle = styled.h3`${tw` text-text-color text-3xl text-left 
px-10 lg:px-32 xl:px-56 2xl:px-56 md:px-10 sm:px-10  pt-10 `}
font-family: 'Amiko';
font-style: bold;
font-weight: bold`;

const ContainerBackgorund = tw.div`items-center justify-center bg-header-color`
const Container = tw.div`relative`;

const BlockImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-97
  bg-contain bg-no-repeat bg-center `
]);

const TopImageBlock = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`h-144
    bg-cover bg-no-repeat bg-center `
  ]);

const SubheadingTitle = styled.p`${tw`text-left text-xl xxs:text-lg xs:text-xl lg:text-xl xl:text-xl 2xl:text-xl md:text-xl sm:text-xl text-text-color mx-auto 
pt-10 xxs:pt-5 px-56 2xl:px-56 xl:px-56 lg:px-40 md:px-20 sm:px-20 xs:px-20 xxs:px-10`}
font-family: 'Amiko';
font-weight: 80;
font-style: normal;`;

export default function Horses() {
  const { t } = useTranslation();
  
  
 
  
  return (
    <>
      <ContainerBackgorund> 
        <Container>
          {/* <TopTitle>{t("bookNow").toUpperCase()}</TopTitle> */}
          <TopBlock imageSrc={Booking1} title={t("booking").toUpperCase()} height={'750px'} textColor={'#FFF'}/>

          <HeadingTitle text={t("Season2023").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}></HeadingTitle>
          <div style={{height: '80px'}}> </div>
          <SubheadingTitle>{t("bookNowBody").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <div style={{height: '100px'}}> </div>
          <HeadingTitle text={t("ourRates").toUpperCase()}></HeadingTitle>
          <BodyTitle>{t("adults").toUpperCase()}</BodyTitle>
          <SubheadingTitle>{t("adultsRates").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <div style={{height: '50px'}}> </div>
          <BodyTitle>{t("children").toUpperCase()}</BodyTitle>
          <SubheadingTitle>{t("childrenRates").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <div style={{height: '50px'}}> </div>
          <SubheadingTitle>{t("cardAccepted").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <div style={{height: '80px'}}> </div>
          <BookContainer link={'https://form.jotform.com/231198092902053'}></BookContainer>
          <div style={{height: '80px'}}> </div>
          <BodyTitle>{t("transport").toUpperCase()}</BodyTitle>
          <div style={{height: '40px'}}> </div>
          <SubheadingTitle>{t("transportBody").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <div style={{height: '60px'}}> </div>
          <BodyTitle>{t("cancelationPolicy").toUpperCase()}</BodyTitle>
          <div style={{height: '40px'}}> </div>
          <SubheadingTitle>{t("cancelationPolicyBody").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <div style={{height: '70px'}}> </div>
          <BodyTitle>{t("termsAndConditions").toUpperCase()}</BodyTitle>
          <div style={{height: '40px'}}> </div>
          <SubheadingTitle>{t("termsAndConditionsBody").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <div style={{height: '50px'}}> </div>
          <BodyTitle>{t("faq").toUpperCase()}</BodyTitle>
          <div style={{height: '40px'}}> </div>
          <SubheadingTitle>{t("faqBody").split("\n").map((bodyTexLine, i) => ( <> {bodyTexLine} <br></br> </>))}</SubheadingTitle>
          <div style={{height: '70px'}}> </div>
          <BookContainer link={'https://form.jotform.com/231198092902053'}></BookContainer>
          <div style={{height: '80px'}}> </div>
        </Container>
      </ContainerBackgorund> 
    </>
  )
    
  }

  


