import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useTranslation } from 'react-i18next';
import { SectionHeading } from "../../components/misc/Headings.js";
import {ButtonsCustom} from "components/misc/ButtonsCustom.js";
import ThreeColSimple from "components/features/ThreeColSimple.js";
import buttonBckground from  "images/buttonBackground.svg";
import HeadingTitle from "components/features/HeadingTitle.js";
import {CustomSubtitleBlock, CustomParagraphBlock, CustomParagraphNoteBlock} from "components/Common.js";


const Container = tw.div`relative`;
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-24 3xs:py-10 xxs:py-10 xs:py-10 lg:py-24 xl:py-20 2xl:py-20 md:py-20 sm:py-20 content-around`;
const Content = tw.div`mt-0`;

const ButtonImage = styled.div(props => [
  `background-image: url("${buttonBckground}");`,
  tw`h-10 pt-2 
  bg-contain bg-no-repeat bg-center `
]);

const Card = styled.div(props => [
  tw`
  // md:flex 
  lg:flex
  justify-center items-center
  `,

  (props.reversed ? tw`flex-row-reverse` :  tw`flex-row`), 
  ((props.nof > 1) && (props.i != 0) ) ? tw`mt-16` : tw`mt-0`,
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`
  h-80 xxs:h-80 xs:h-100 sm:h-144 md:h-130 lg:h-130 xl:h-144 2xl:h-144
  w-80 xxs:w-80 xs:w-100 sm:w-144 md:w-130 lg:w-130 xl:w-144 2xl:w-144
  justify-center items-center
  bg-cover bg-no-repeat `
]);
const Details = tw.div`

mt-4 md:mt-0 
// md:max-w-md 
mx-4 sm:mx-8 md:mx-20 lg:mx-8 
flex flex-col 
justify-center items-center


`;
const SpaceContainer = tw.div`relative h-50 3xs:h-px xxs:h-px xs:h-px lg:h-50 xl:h-50 2xl:h-50 md:h-50 sm:h-px`;
const SpaceContainerMobile = tw.div`
relative 
h-0 xxs:h-16 xs:h-16 sm:h-16 md:h-0 lg:h-0 2xl:h-0
`;

const TextContent = tw.div``;
const Heading = styled(SectionHeading)`${tw`text-center text-lg leading-tight text-text-color pb-10`};
font-family: 'ultra';
font-style: normal;
font-weight: 800;`
const SubDescription = tw.p`text-center font-medium leading-relaxed text-secondary-100 mt-1 text-text-color`
const SubDescriptionLeft = tw.p`text-left font-medium leading-relaxed text-secondary-100 mt-1 text-text-color`
const ButtonText = styled.h2`${tw` text-text-color text-xs text-center 
  px-10 pt-1`}
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 80`;

const Button = tw.button`w-full sm:w-auto mt-6 sm:mt-0 py-4 text-black text-center`

// const MobileButton = tw(ButtonsCustom)` w-1/2 sm:w-1/2 mt-0 sm:mt-0 rounded py-4 text-center text-black`
const ThreeColumnContainer = styled.div`${tw`mt-0 3xs:mt-0 xxs:mt-0 xs:mt-0 lg:mt-10  xl:mt-10  2xl:mt-10  md:mt-10  sm:mt-0 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center  mx-auto`}`

export default ({blocks = [{}]
}) => {

  const { t } = useTranslation();
 

  return (
    <Container>
      <SingleColumn>
        <Content>
          {blocks.map((block, i) => (
            <>
              <Card key={i} reversed={i % 2 === 0} nof={blocks.lengt
              } i={i}>
                {
                  (block.textOnLeft ?? false) != false && 
                  <center><Image imageSrc={block.imagePath} /></center>
                }
                <Details>
                  <TextContent>
                    {/* <HeadingTitle text={block.title.toUpperCase()}/> */}
                    <CustomSubtitleBlock>{block.title.toUpperCase()}</CustomSubtitleBlock>
                    
                    {
                  (block.alignLeft ?? false) == false && 
                  // <SubDescription>{block.body}</SubDescription>
                    <>
                      <CustomParagraphBlock>{block.body}</CustomParagraphBlock>
                      <CustomParagraphNoteBlock>{block.note}</CustomParagraphNoteBlock>
                    </>
               
                }
                {     
                  (block.alignLeft ?? false) == true && 
                  // <SubDescriptionLeft>{block.body}</SubDescriptionLeft>
                
                    <>
                      <CustomParagraphBlock>{block.body}</CustomParagraphBlock>
                      <CustomParagraphNoteBlock>{block.note}</CustomParagraphNoteBlock>
                    </>
                }
                    
                    <SpaceContainer ></SpaceContainer>
                   
                    <center>
                      {block.href != undefined  &&
                        // <MobileButton as="a" href={block.href}>{t("viewMore").toUpperCase()} </MobileButton>
                          <>
                           <SpaceContainerMobile ></SpaceContainerMobile>
                           <Button as="a" href={block.href}>
                            
                            <ButtonImage >
                            <ButtonText>
                            {t("viewMore").toUpperCase()} 
                            </ButtonText>
                            </ButtonImage>
                           </Button>
                          </>
                          
                      }
                    </center>
                  </TextContent>
                </Details>
                {(block.textOnLeft ?? false) != true && <center><Image imageSrc={block.imagePath} /></center> }
              </Card>
             
                <Container  style={{height: "30px"}}></Container>
              
            </>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};